/* You can add global styles to this file, and also import other style files */
/*TODO - change to scss*/

/*Pre-loader classes*/
.main-preloader-container {
    width: 56px;
    height: 56px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -28px;
    margin-left: -28px;
}

.preloader-image-container {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 2px solid #fff;
    z-index: 101;
    position: relative;
    margin-top: -52px;
    margin-left: 2px;
    background-color: #c0c0c0;
}

    .preloader-image-container img {
        margin: 8px 8px;
    }

.preloader-text {
    font-family: 'Noto Sans', sans-serif;
    width: 90px;
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: 28px;
    margin-left: -40px;
    color: #333;
}

    .preloader-text:not(.error):after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: preloader-ellipsis-loader steps(4,end) 1.1s infinite;
        -moz-animation: preloader-ellipsis-loader steps(4,end) 1.1s infinite;
        -o-animation: preloader-ellipsis-loader steps(4,end) 1.1s infinite;
        animation: preloader-ellipsis-loader steps(4,end) 1.1s infinite;
        content: "\2026";
        width: 0px;
    }

@keyframes preloader-ellipsis-loader {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes preloader-ellipsis-loader {
    to {
        width: 1.25em;
    }
}

@-moz-keyframes preloader-ellipsis-loader {
    to {
        width: 1.25em;
    }
}

@-o-keyframes preloader-ellipsis-loader {
    to {
        width: 1.25em;
    }
}

.main-preloader {
    width: 54px;
    height: 54px;
    border-radius: 100%;
    border: 2px solid;
    border-top-color: #C0C0C0;
    border-bottom-color: #FEE600;
    border-left-color: #FEE600;
    border-right-color: #FEE600;
    -webkit-animation: mainpreloader 1s ease-in-out infinite;
    -moz-animation: mainpreloader 1s ease-in-out infinite;
    -o-animation: mainpreloader 1s ease-in-out infinite;
    animation: mainpreloader 1s ease-in-out infinite;
    z-index: 100;
}

@-moz-keyframes mainpreloader {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes mainpreloader {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@-o-keyframes mainpreloader {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes mainpreloader {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.preloader-error {
    width: 100%;
    display: inline-block;
    position: absolute;
    top: 50%;
    margin-top: 28px;
    text-align: center;
    color:#333;
}

.main-preloader-stopped {
    animation: none !important;
}

.preloader-text.error {
    width: 555px;
    margin-left: -250px;
}

    .preloader-text.error span {
        color: #564CAF;
        cursor: pointer;
    }
/*Pre-loader classes end*/



/*button styling based on style guid start*/
.btn {
    line-height: 1.5em;
    min-width: 100px;
    border-radius: 0;
    /*border-right: solid 1px #333333;
    border-bottom: solid 1px #333333;*/
    box-shadow: none;
    background-color: #c0c0c0;
    min-height:38px;
    height:42px;
    font-size:16px;
}

    .btn:hover {
        border-right: solid 1px transparent;
        border-bottom: solid 1px transparent;
    }

.btn-ey-default {
    color: #333333;
    background-color: #FEE600;
    border-color: #FEE600;
    font-size:14px;
    /*border-right: solid 1px #c0c0c0;
    border-bottom: solid 1px #c0c0c0;*/
    min-height: 38px;
}

    .btn-ey-default:hover,
    .btn-ey-default:focus,
    .btn-ey-default:active,
    .open .dropdown-toggle.btn-ey-default {
        color: #333333;
        background-color: #FEE600;
        border-color: #FEE600;
    }

.btn-ey-default-gray {
    color: #ffffff;
    background-color: #C0C0C0;
    border-color: #C0C0C0;
    font-size: 14px;
    min-height: 38px;
}

    .btn-ey-default-gray:hover,
    .btn-ey-default-gray:focus,
    .btn-ey-default-gray:active,
    .open .dropdown-toggle.btn-ey-default-gray {
        color: #333333;
        background-color: #C0C0C0;
        border-color: #C0C0C0;
    }

.btn-ey-dark-gray {
    color: #FFF;
    background-color: #666;
}

    .btn-ey-dark-gray:hover,
    .btn-ey-dark-gray:focus,
    .btn-ey-dark-gray:active {
        color: #FFF;
        background-color: #666;
    }

.btn-disabled {
    opacity: 0.65;
    cursor: not-allowed;
}
/*button styling based on style guide end*/


/*Dialog Modal styles start*/
.ey-dialog-modal .modal-content,.ey-alert-modal .modal-content,  .ey-info-modal .modal-content{
    color: #5F5F5F;
    font-size: 14px;
    border-radius: 5px;
}

.ey-dialog-modal .modal-header, .ey-alert-modal .modal-header, .ey-info-modal .modal-header {
    border: none;
}

    .ey-dialog-modal .modal-header h1, .ey-alert-modal .modal-header h1, .ey-info-modal .modal-header h1 {
        font-size: 14px;
        font-weight: bold;
    }

.ey-dialog-modal .modal-footer, .ey-alert-modal .modal-footer, .ey-info-modal .modal-footer {
    border: none;
    text-align: left;
}

.ey-dialog-modal .modal-header button, .ey-alert-modal .modal-header button, .ey-info-modal .modal-header button {
    background-color: transparent;
    filter: alpha(opacity=100);
    opacity: 1;
}

    .ey-dialog-modal .modal-header button:hover, .ey-alert-modal .modal-header button:hover, .ey-info-modal .modal-header button:hover {
        background-color: transparent;
        filter: alpha(opacity=100);
        opacity: 1;
    }

    .ey-dialog-modal .modal-header button.close span, .ey-alert-modal .modal-header button.close span, .ey-info-modal .modal-header button.close span {
        color: #CCCCCC;
    }

    .ey-dialog-modal .modal-header button.close, .ey-alert-modal .modal-header button.close, .ey-info-modal .modal-header button.close {
        box-shadow: none;
    }

.ey-info-modal {
    margin-top: 0px;
}

    .ey-info-modal.modal {
        text-align: center;
        padding: 0 !important;
    }

        .ey-info-modal.modal:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -4px;
        }

    .ey-info-modal .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        width:auto;
    }

    .ey-info-modal-default{
        width:600px;
    }

.floating-dialog-close {
    position: relative;
    margin-top: -35px;
    color: #fff;
    text-transform: uppercase;
    margin-right: -10px;
    font-size: 10px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
}

/*Dialog Modal styles end*/
/*Progress Bar start*/
/*.loading-body {
    overflow: hidden;
}

    .loading-body .progressbar-disabled-overlay, .loading-body .progressbar-progress-outer {
        display: block !important;
    }*/


.page-loading .page-transition
{
    display:block !important;
}

.page-posting {
    overflow: hidden
}

.page-posting .page-posting-overlay-container {
    display: block !important;
}

.in-page-progressbar-container
{
    height:100%;
    width:100%;
    min-height:100px;
    background-color:#fff;
    opacity:.7;
    position:absolute;
    z-index:1010;
    display:block;
    left:0;
    top:0;
}

    .in-page-progressbar-container .in-page-progressbar {
        width: 100px;
        height: 100px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -50px;
        margin-left: -50px;
        border-radius: 50px;
        background-color: transparent;
        border: solid 3px transparent;
        border-top: solid 3px #FFEE00;
        border-right: solid 3px #FFEE00;
        border-bottom: solid 3px #FFEE00;
        /*box-shadow:inset 0 0 0 3px;*/
        -webkit-animation: inpageProgress 1s infinite ease-in-out;
        -moz-animation: inpageProgress 1s infinite ease-in-out;
        -o-animation: inpageProgress 1s infinite ease-in-out;
        animation: inpageProgress 1s infinite ease-in-out;
    }

@-webkit-keyframes inpageProgress {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes inpageProgress {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(360deg);
    }
}

@-o-keyframes inpageProgress {
    0% {
        -o-transform: rotate(0deg);
    }

    100% {
        -o-transform: rotate(360deg);
    }
}

@keyframes inpageProgress {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
/*Progress Bar end*/
.clear-fix {
    clear: both;
}

.no-padding-column {
    padding-left:0;
    padding-right:0;
}

@media only screen and (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

@media only screen and (min-width: 1600px) {
    .container {
        width: 1420px;
    }
}

@media only screen and (min-width: 1900px) {
    .container {
        width: 1600px;
    }
}

/*
    EYV Velocity tooltip styles
*/

.eyv-tooltip-container {
    position: absolute;
    opacity:0;
    z-index: 1020;
    padding: 5px 10px;
    border-radius: 5px;
    border: solid 1px #C0C0C0;
    background-color: #fff;
    min-width: 200px;
    max-width:400px;
    line-height: 21px;
    text-align: left;
    vertical-align: top;
    font-size: 14px;
    color: #F04C3E;
    box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.47);
    min-height:52px;
}

.tooltip-show {
    opacity:1;
    transition:opacity .15s linear;
}


    .eyv-tooltip-container .eyv-callout {
        position: absolute;
        right: 20px;
        bottom: 0px;
        margin-bottom: -10px;
        width: 0;
        height: 0;
        border-top: solid 10px #c0c0c0;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
        z-index: 1022;
    }

.eyv-callout::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: solid 8px #fff;
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
    z-index: 1021;
    margin-top: -10px;
    margin-left: -8px;
}

.dnd-drag-start {
    /*-moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;*/
    opacity: 0.7;
    border: 1px dashed #000;
}

.dnd-drag-enter {
    opacity: 0.7;
    border: 1px dashed #000 !important;
}

.dnd-drag-over {
    border: 1px dashed #000 !important;
}

.dnd-sortable-drag {
    /*-moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;*/
    opacity: 0.7;
    border: 1px dashed #000;
}
input::-ms-clear {
    display: none;
}
input::placeholder {
    opacity:0.25;
    font-family:"EYInterState-Light";
}

input::-webkit-input-placeholder {
    opacity: 0.25;
    font-family: "EYInterState-Light";
}

input::-moz-placeholder {
    opacity: 0.25;
    font-family: "EYInterState-Light";
}

input::-ms-input-placeholder {
    opacity: 0.25;
    font-family: "EYInterState-Light";
}

input::-o-placeholder {
    opacity: 0.25;
    font-family: "EYInterState-Light";
}
.ad-container {
    width: 80% !important;
    max-width:1300px;
    margin: 10%;
}

button:focus {
    box-shadow: none !important;
}